<template>
    <div v-myTitle :data-title="shop.name+'-'+shop.shopName+'-'+'服务中心'+'-'+'企胖胖'">
        <Search />
        <div class="head">
            <div class="nav">
                <div @click="goBack">
                    <i style="font-size: 20px; padding-top: 2px" class="el-icon-arrow-left"></i>
                    返回店铺首页
                </div>
            </div>
        </div>
        <div class="detail">
            <div class="detail-img">
                <el-carousel height="550px" arrow="never" trigger="click">
                    <el-carousel-item v-for="item in bannerList" :key="item.id">
                        <div class="swiper">
                            <img :src="$util.host + item" alt="" />
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <!-- <img :src="$util.host + shop.cover" alt="" /> -->
            </div>
            <div class="detail-info">
                <div class="title">{{ shop.name }}</div>
                <div class="budget">
                    <div>预算</div>
                    <div class="red">￥{{ shop.budget }}</div>
                    <div>
                        访问量 <span>{{ shop.views }}</span>
                    </div>
                </div>
                <div class="shop-name">
                    <span>店铺</span>
                    {{ shop.shopName }}
                </div>
                <div class="introduction">
                    服务简介
                    <div>{{ shop.introduce }}</div>
                </div>
                <div class="cycle">
                    <div>
                        订单平均完成周期 <span>{{ shop.expectDay }}天</span>
                    </div>
                    <div class="shu"></div>
                    <div>
                        订单平均响应周期 <span>{{ shop.answerHour }}小时</span>
                    </div>
                </div>
                <div class="area" v-if="shop.address.length > 0">
                    <div class="lable">服务地区</div>
                    <div class="tags">
                        <div v-for="(item, index) in shop.address" :class="index == areaIndex ? 'active' : ''" @click="areaIndex = index" :key="index">
                            {{ item }}
                        </div>
                    </div>
                </div>
                <div class="area" v-if="shop.norms[0] != ''">
                    <div class="lable">服务规格(用企业人数)</div>
                    <div class="tags">
                        <div v-for="(item, index) in shop.norms" :class="index == peopleIndex ? 'active' : ''" @click="peopleIndex = index" :key="index">
                            {{ item }}
                        </div>
                    </div>
                </div>
                <div class="area" v-if="shop.term[0] != ''">
                    <div class="lable">有效期</div>
                    <div class="tags">
                        <div v-for="(item, index) in shop.term" :class="index == yearsIndex ? 'active' : ''" @click="yearsIndex = index" :key="index">
                            {{ item }}
                        </div>
                    </div>
                </div>
                <div class="area">
                    <div class="lable">数量</div>
                    <el-input-number v-if="shop.num == -1" v-model="num" :min="1"></el-input-number>
                    <el-input-number v-else v-model="num" :min="1" :max="shop.num"></el-input-number>
                </div>
                <div class="btn">
                    <el-button style="
              width: 180px;
              height: 50px;
              color: #fff;
              font-size: 18px;
              background: #e40012;
            " @click="buy">购买服务</el-button>
                    <el-button style="
              width: 180px;
              height: 50px;
              color: #e40012;
              font-size: 18px;
              border: 1px solid #e40012;
            " @click="wachtShow = true">微信咨询</el-button>
                </div>
            </div>
        </div>
        <div class="tabs">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="服务详情" name="first">
                    <div class="shop-detail" v-html="shop.content"></div>
                </el-tab-pane>
                <el-tab-pane label="服务评价" name="second">
                    <div class="menus">
                        <div class="box">
                            <div>+{{ evaluateNum }}</div>
                            评价
                        </div>
                        <div class="box">
                            <div>{{ shopInfo.evaluateRate }}%</div>
                            好评率
                        </div>
                        <div class="box">
                            <div>+{{ shop.goodsSize }}</div>
                            成交量
                        </div>
                    </div>
                    <div class="comment-box">
                        <div class="comment" v-for="item in evaluateList" :key="item">
                            <img :src="$util.host + item.userHeadPic" alt="" />
                            <div class="comment-info">
                                <div class="user-info">
                                    <div class="name">
                                        <div>{{ item.userName }}</div>
                                        <el-rate v-model="item.level" disabled show-text :colors="colors" :texts="['极差', '失望', '一般', '满意', '好评']" text-color="#666666">
                                        </el-rate>
                                    </div>
                                    <div>{{ item.createTime.slice(0, item.createTime.length - 9) }}</div>
                                </div>
                                <div class="comment-content">
                                    {{ item.txt }}
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <!-- 弹出微信 -->
        <el-dialog :visible.sync="wachtShow" width="300px">
            <div class="wechat">微信扫一扫与我们沟通更方便</div>
            <div class="wechat-img">
                <img :src="$util.host + shop.wxCode" alt="" />
            </div>
        </el-dialog>
        <ByGood v-if="dialogVisible" :dialogVisible="dialogVisible" :goodInfo="shop" :shopNum="num" @close="close" />
        <Complete :show="show" @close="close" />
        <Footer />
    </div>
</template>

<script>
import Search from "@/components/search.vue";
import Footer from "../../components/footer.vue";
import ByGood from "../../components/serve/byGood.vue";
import Complete from "../../components/complete.vue";
import eventBus from '@/components/eventBus.js'
export default {
    components: { Search, Footer, ByGood, Complete },
    data() {
        return {
            bannerList: "",
            wachtShow: false, //弹出微信
            peopleIndex: 0, //人数下标
            yearsIndex: 0, //时间下标
            areaIndex: 0, //地址下标
            areaList: [
                { id: 0, name: "全国" },
                { id: 1, name: "北京" },
                { id: 2, name: "广东深圳" },
            ], //废弃
            speciList: [
                { id: 0, name: "1-10人" },
                { id: 1, name: "11-25人" },
                { id: 2, name: "26-45人" },
                { id: 3, name: "26-45人" },
                { id: 4, name: "26-45人" },
                { id: 5, name: "26-45人" },
            ], //废弃
            timeList: [
                { id: 0, name: "1年" },
                { id: 1, name: "1年" },
                { id: 2, name: "1年" },
                { id: 3, name: "1年" },
                { id: 4, name: "1年" },
            ], //废弃
            num: 1, //选择数量
            activeName: "first", //当前下标
            current: 0, //当前下标
            star: 5,
            show: false, //是否完善资料弹出层
            colors: ["#E40012", "#E40012", "#E40012"],
            dialogVisible: false, //购买商品弹出层
            id: this.$route.query.id, //当前商品ID
            shop: {}, //商品详情信息
            shopInfo: {},
            evaluateNum: "",
            evaluateList: "",
        };
    },
    mounted() {
        this.allData();
    },
    methods: {
        getShopInfo() {
            this.$util
                .post("/demandPurchase/shopDet", {
                    shopId: this.shop.shopId,
                })
                .then((res) => {
                    this.shopInfo = res.data.shop;
                });
        },
        getEvaluate() {
            this.$util
                .post("/demandPurchase/evaluateOrderList", {
                    goodsId: this.id,
                    shopId: this.shop.shopId,
                })
                .then((res) => {
                    console.log(res);
                    this.evaluateList = res.data;
                    this.evaluateNum = res.pager.totalRows;
                });
        },
        allData() {
            this.$util
                .post("/demandPurchase/goodsDet", {
                    goodsId: this.id,
                })
                .then((res) => {
                    console.log(res);
                    this.bannerList = res.data.banner.split(",");
                    res.data.shopName = this.$route.query.name;
                    res.data.address = res.data.address.split(",");
                    res.data.norms = res.data.norms.split(",");
                    res.data.term = res.data.term.split(",");
                    res.data.state = 0;
                    this.shop = res.data;
                    console.log(this.shop);
                    this.getEvaluate();
                    this.getShopInfo();
                });
        },
        handleClick(tab) {
            this.current = tab.index;
        },
        close() {
            this.dialogVisible = false;
            this.show = false;
        },
        buy() {
            if (sessionStorage.getItem("token") && sessionStorage.getItem("payPsd") == "true") {
                this.dialogVisible = true;
            } else if (!sessionStorage.getItem("token")) {
                this.$message.error("请先进行登录");
            } else if (sessionStorage.getItem("payPsd") == "false") {
                this.show = true;
            }
        },
        goBack() {
			this.$router.replace(`/shop?shopNum=${this.$route.query.shopNum}&id=${this.$route.query.bigId}`)
            // this.$router.go(-1);
        },
    },
};
</script>

<style lang="scss" scoped>
.wechat {
    font-size: 16px;
    text-align: center;
    color: #222;
}
.wechat-img {
    width: 191px;
    height: 191px;
    margin: 30px auto 0;
    // background: red;
    img {
        width: 100%;
        height: 100%;
    }
}
.head {
    padding: 12px 0;
    background: #515151;
    .nav {
        width: 1200px;
        display: flex;
        margin: 0 auto;
        color: #fff;
        div {
            display: flex;
            cursor: pointer;
            align-items: center;
            padding-right: 53px;
            font-size: 14px;
        }
        .bor {
            border-right: 1px solid #707070;
            margin-right: 50px;
        }
    }
}
.detail {
    width: 1160px;
    padding: 20px;
    margin: 30px auto;
    border: 1px solid #eeeeee;
    display: flex;
}
.detail-img {
    width: 720px;
    height: 554px;
    // background: red;
    margin-right: 20px;
    img {
        width: 100%;
        height: 100%;
    }
}
.detail-info {
    flex: 1;
    .title {
        font-size: 22px;
        color: #222222;
        font-weight: bold;
    }
    .budget {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 30px 0 20px;
        color: #999999;
        font-size: 14px;
        font-weight: bold;
        span {
            color: #222222;
            font-size: 16px;
        }
        .red {
            color: #e40012;
        }
    }
    .shop-name {
        color: #222222;
        font-size: 16px;
        span {
            font-size: 14px;
            color: #999;
            margin-right: 60px;
        }
    }
    .introduction {
        display: flex;
        color: #999999;
        font-size: 14px;
        padding: 20px 0 30px;
        border-bottom: 1px solid #f1f1f1;
        div {
            flex: 1;
            margin-left: 35px;
            color: #222;
            font-size: 12px;
        }
    }
    .cycle {
        display: flex;
        font-size: 16px;
        color: #222;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0;
        font-weight: bold;
        .shu {
            width: 2px;
            height: 24px;
            background: #f1f1f1;
        }
        span {
            color: #e40012;
            margin-left: 10px;
        }
    }
    .area {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #999;
        margin-bottom: 20px;
        .lable {
            width: 80px;
            margin-right: 30px;
        }
        .tags {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            div {
                cursor: pointer;
                padding: 6px 14px;
                border: 1px solid #dbdbdb;
                margin-right: 10px;
                border-radius: 4px;
                margin-bottom: 10px;
            }
            .active {
                padding: 6px 14px;
                border: 1px solid #e40012;
                color: #e40012;
            }
        }
    }
    .btn {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
    }
}
.tabs {
    width: 1200px;
    margin: 0 auto;
}
/deep/.el-tabs__item.is-active {
    color: #e40012;
}
/deep/.el-tabs__active-bar {
    background-color: #e40012 !important;
    width: 80px !important;
}
/deep/.el-tabs__item {
    font-size: 22px;
    color: #666666;
    width: 120px;
    text-align: center;
}
/deep/.el-tabs__item:hover {
    color: #e40012;
}
.shop-detail {
    padding: 20px;
    margin-top: 30px;
    border: 1px solid #eeeeee;
    font-size: 14px;
    color: #222;
    margin-bottom: 30px;

    /deep/ img {
        max-width: 100%;
    }
}
.shop-content {
    border: 1px solid #707070;
    // width: 1200px;
    height: 400px;
    margin-bottom: 20px;
}
.menus {
    display: flex;
    justify-content: space-between;
    margin: 30px 0 50px;
    .box {
        width: 380px;
        color: #e40012;
        padding: 40px 0;
        font-size: 24px;
        text-align: center;
        // background: #e40012;
        border: 1px solid #e40012;
        div {
            font-size: 38px;
            font-weight: bold;
            margin-bottom: 15px;
        }
    }
}
.comment-box {
    width: 1200px;
    margin: 0 auto 20px;
}
.comment {
    display: flex;
    padding: 30px 20px;
    border-bottom: 1px solid #f1f1f1;
    img {
        width: 82px;
        height: 82px;
    }
    .comment-info {
        flex: 1;
        margin-left: 20px;
        font-size: 14px;
        color: #999999;
        .user-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #222;
            .name {
                display: flex;
                align-items: center;
                div {
                    margin-right: 20px;
                }
            }
        }
    }
}
.comment-content {
    margin: 13px 0 20px;
    font-size: 14px;
    color: #666;
}
.comment-serve {
    margin-bottom: 10px;
    span {
        color: #e40012;
    }
}
.wechat {
    font-size: 16px;
    text-align: center;
    color: #222;
}
</style>
